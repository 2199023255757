import React from 'react'
import SectionContainer from '../../SectionContainer'
import { anchorToElement } from '../../../../helpers/anchorToElement'

import './styles.scss'

const ClientSolutionAndSummary = ({ client, solutions, brand, summary }) => {
  return (
    <SectionContainer className="client-solution-and-summary">
      <div className="client-solution-and-summary__title">
        <p>
          <strong>Cliente:</strong> {client}
        </p>
        <p>
          <strong>Soluções:</strong> {solutions}
        </p>
      </div>

      <div className="client-solution-and-summary__list">
        <strong>
          Veja o sumário {brand ? `do projeto da ${brand} a seguir` : 'logo abaixo'}:
        </strong>
        <ul>
          {summary.map((item) => (
            <li>
              <div
                onClick={(e) => anchorToElement(e)}
                data-section={item.href.replace('#', '')}
              >
                {item.text}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </SectionContainer>
  )
}

export default ClientSolutionAndSummary
